import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "gatsby"

import logo from "../images/logo+transparent.jpg"

const Header = ({ siteTitle }) => (
  <Navbar
    collapseOnSelect
    expand="lg"
    className="sunrise-nav"
    style={{
      marginBottom: `1.45rem`
    }}
  >
    <a class="nav-text" href="https://dataforprogress.org" target="_blank" rel="noreferrer">
      <img src={logo} alt="Data for Progress" width="20%"  />
      <Link className="nav-text" to="">&nbsp;&nbsp;&nbsp;</Link>
      <Link className="nav-text" to="/">{siteTitle}</Link>
    </a>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="ml-auto">
        <Link className="nav-link" to="/">Home</Link>
        <Link className="nav-link" to="/compare">Compare Occupations</Link>
        <Link className="nav-link" to="/about">About</Link>
        <Link className="nav-link" to="/terminology">Terminology</Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)

export default Header
